.input-password-container {
    position: relative
  }
  
  .input-password-container .icon-view-password, .input-password-container .icon-hide-password {
    position: absolute;
    font-size: 18px;
    cursor: pointer;
    z-index: 9999;
    top: 50%;
    right: 10px;
    -ms-transform: translate(0, -50%);
    transform: translate(0,-50%);
  }
  
  .input-password-container input {
    padding-right: 8% !important;
  }