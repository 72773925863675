body {
  padding-top: 70px;
  margin-bottom: 70px;
  font-family: sans-serif, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  color: rgba(85, 85, 85, 0.94) !important;
  font-size: 14px !important;
  line-height: 1.42857143 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.has-error .form-control {
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
}

